import {useTranslation} from "react-i18next";
import {deleteMyAccount} from "../store/api";
import React, {useState} from "react";
import {
  useHistory,
  useLocation,
  Link as LinkRouter,
} from "react-router-dom";
import Divider from "@mui/material/Divider";

import {connect, useDispatch, useSelector} from "react-redux";
import makeStyles from "@mui/styles/makeStyles";
import {
  onLangChange,
} from "../store/features/newRequestSlice";
import PopupState, {bindTrigger, bindMenu} from "material-ui-popup-state";
import LanguageIcon from "@mui/icons-material/Language";

import {onSearchChange} from "../store/features/requestListSlice";
import mobilelogo from "assets/img/brand/logo_mobile_compress.png";
import Link from "@mui/material/Link";
import MenuIcon from "@mui/icons-material/Menu";
import {cleanStoreWithoutDoctor} from "../pages/Wizard/wizardSlice";
import {logout, login, passwordEdit, profileEdit} from "../config/msalProvider.js";
import {AuthenticatedTemplate, UnauthenticatedTemplate} from "@azure/msal-react";
import {
  NotificationsActive,
  Logout,
  Login,

  // Chat,
} from "components/icons";
import EmailIcon from "@mui/icons-material/Email";

import NotificationDialog from "components/dialogs/NotificationDialog";
import {
  fetchRequestsForExport,
  deleteAllRequests,
} from "store/middleware/requestListThunk";
import {
  GetHeaderRoutes,
  getUnauthenticatedRoutes,
} from "config/routes";
import {AppBar,
  Box,
  Toolbar,
  Container,
  Badge,
  Button,
  MenuItem,
  Menu,
} from "@mui/material";

import VerificationDialog from "../components/dialogs/VerificationDialog";


const useStyles = makeStyles({
  toolbarContainer: {
    "borderBottom": "1px solid #c8ced3",
    "flex-wrap": " wrap",
    "align-items": "center",
    "justify-content": "space-between",
  },

});

const DefaultHeader = (props) => {
  const history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();
  const location = useLocation();
  const [notificationsRead, setNotificationsRead] = useState(false);
  const [notificationDialogValue, setNotificatioDialogValue] = useState(false);

  const [verificationDialogValue, setVerificationDialogValue] = useState(false);

  const notificationList = useSelector((state) => state.requestList.notificationList);
  const onCloseNotificationDialog = () => setNotificatioDialogValue(false);

  const {t, i18n} = useTranslation();
  const HeaderRoutesArray = GetHeaderRoutes(props.verified, i18n);
  const unauthenticatedRoutes = getUnauthenticatedRoutes( i18n);


  const handleChangeLanguage = (newValue)=> {
    console.debug("handleChangeLanguage", newValue);
    i18n.changeLanguage(newValue);
    props.changeLang(newValue);
  };

  const handleDeleteMyAccount = async ()=> {
    await deleteMyAccount();
    logout();
  };

  const handleDeleteAllRequests = async () => dispatch(deleteAllRequests());
  const handleChangeProfile =async ()=> profileEdit();
  const handleEditPassword = (e)=> passwordEdit();
  const handleExportData = ()=>dispatch(fetchRequestsForExport());
  const handleRoutesChange = (path, isAuthenticated)=> {
    console.debug("HeaderRoutesArray", path);
    if (path === "/requests" && props.searchQuery.length !== 0 ) {
      dispatch(onSearchChange(""),
      );
    };
    if (path.startsWith("/wizard") && window.location.pathname.startsWith("/wizard")) {
      if (window.confirm( t("navigate.away.prompt")) === true) {
        console.debug("cleanStoreWithoutDoctor");
        dispatch(cleanStoreWithoutDoctor({}));
      }
    }
  };

  return (
    <AppBar position="sticky" color="inherit" elevation={0} className={classes.toolbarContainer} >
      <Container maxWidth={"xxl"} >
        <Toolbar disableGutters variant='dense' >
          <AuthenticatedTemplate>


            <Box
              sx={{
                display: {xs: "flex", lg: "none"},
              }}
              alt="logo"
            >
              <a href="/">
                <img alt="logo" src={mobilelogo} style={{width: "40px", height: "40px"}} ></img> <br />
              </a>
            </Box>
            <Box
              sx={{
                display: {xs: "none", lg: "block"},
              }}
              alt="logo."
            >
              <a href="/">
                {
                  // <img alt="logo" src={logo} style={{width: "160px", height: "18px"}} ></img> <br />
                }
                <img alt="logo" src={mobilelogo} style={{width: "40px", height: "40px"}} ></img> <br />
              </a>
            </Box>
          </AuthenticatedTemplate>
          <UnauthenticatedTemplate>
            <Box
              sx={{
                display: {xs: "flex", lg: "none"},
              }}
              alt="logo"
            >
              <a href="/wizard">
                <img alt="logo" src={mobilelogo} style={{width: "40px", height: "40px"}} ></img> <br />
              </a>
            </Box>
            <Box
              sx={{
                display: {xs: "none", lg: "block"},
              }}
              alt="logo."
            >
              <a href="/wizard">
                {
                  // <img alt="logo" src={logo} style={{width: "160px", height: "18px"}} ></img> <br />
                }
                <img alt="logo" src={mobilelogo} style={{width: "40px", height: "40px"}} ></img> <br />
              </a>
            </Box>
          </UnauthenticatedTemplate>
          <AuthenticatedTemplate>


            <Box
              sx={{
                alignContent: "space-between",
                alignItems: "center",
                display: {xs: "none", lg: "flex"},
              }}>
              {
                HeaderRoutesArray.map((e) =>
                  <LinkRouter to={e.to} key={(e.name)} >
                    { console.debug("Button:") }
                    { console.debug("e.path: ", e.path) }
                    {console.debug("location.pathname: ", location.pathname) }


                    <Button
                      sx={
                        {
                          "textTransform": "none",
                          "fontSize": "1em",
                          "fontWeight": 700,
                          "opacity": 1,
                          "textDecoration":
                            (
                              e.path.indexOf(location.pathname) > -1 &&
                              location.pathname.length > 1
                            ) ?
                              "underline !important" :
                              "none",
                        }
                      }
                      color={
                        e.path.indexOf(location.pathname) > -1 &&
                        location.pathname.length > 1 ?
                          "primary" :
                          "link"
                      }
                      onClick={()=>handleRoutesChange(e.path, true)}
                      id={e.path}
                      aria-label={e["aria-label"]}
                    >
                      {(props.isDemoUser === true && e.demoOnly === true) || (e.demoOnly === false && e.iconOnly) ?
                     <e.icon fontSize="small"/>:
                      e.name

                      }
                    </Button>
                  </LinkRouter>,
                )
              }
            </Box>
            <Box sx={{
              flexGrow: 1,
              display: "flex",
              alignItems: "center",
              justifyContent: "space-evenly",
            }} />

            <Button
              hidden={props.verified}
              color="alert"
              size="small"
              aria-label="verified_menu_button"
              variant="outlined"
              onClick={() => {
                setVerificationDialogValue(true);
              }}
            >
              {t("unverified.user.title")}
            </Button>
            <VerificationDialog
              open={verificationDialogValue}
              handleClose={() => {
                setVerificationDialogValue(false);
              }
              }
              title={t("unverified.user.title")}
              description={t("unverified.user.text")}
            />


            <Button
              hidden={!props.verified}
              color="link"
              size="small"
              aria-label="open messages"
              onClick={() => {
                history.push("/communication");
              }}
            >
              <Badge
                color="alert"
                variant={props.unreadMessages>0 ? "dot" : "standard"} >
                <EmailIcon fontSize="small"/>
              </Badge>
            </Button>


            <Button
              color="link"
              size="small"
              aria-label="open notifications"
              onClick={() => {
                setNotificatioDialogValue(true);
                setNotificationsRead(true);
              }}
            >
              <Badge
                color="alert"
                variant={(notificationList.length > 0 && !notificationsRead) ? "dot" : "standard"} >
                <NotificationsActive fontSize="small"/>
              </Badge>
            </Button>

            <NotificationDialog
              unreadMessages={props.unreadMessages}
              isOpen={notificationDialogValue}
              onClose={onCloseNotificationDialog}
              itemList={props.itemList} />

            <Box
              sx={{
                alignContent: "space-between",
                alignItems: "center",
                display: {xs: "flex", lg: "none"},
              }}>


            </Box>

            <Button style={{display: "none"}} id="testing_deletemyaccount" onClick={handleDeleteMyAccount} color="link"></Button>
            <Button style={{display: "none"}} id="testing_deletemyrequests" onClick={handleDeleteAllRequests} color="link"></Button>
          </AuthenticatedTemplate>
          <UnauthenticatedTemplate>
            {unauthenticatedRoutes.map((e) =>
              <LinkRouter to={e.to} key={(e.name)} >
                <Button
                  key={(e.name)}
                  sx={
                    {
                      "display": {xs: "none", lg: "block"},
                      "textTransform": "none",
                      "fontSize": "1em",
                      "fontWeight": 700,
                      "opacity": 1,
                      "textDecoration":
                            (
                              e.path.indexOf(location.pathname) > -1 &&
                              location.pathname.length > 1
                            ) ?
                              "underline !important" :
                              "none",
                    }
                  }
                  color={
                        e.path.indexOf(location.pathname) > -1 &&
                        location.pathname.length > 1 ?
                          "primary" :
                          "link"
                  }
                  onClick={()=>handleRoutesChange(e.path, true)}
                  id={e.path}
                  aria-label={e["aria-label"]}
                >
                  {(props.isDemoUser === true && e.demoOnly === true) || (e.demoOnly === false && e.iconOnly) ?
                     <e.icon fontSize="small"/>:
                      e.name

                  }
                </Button>
              </LinkRouter>,
            )}
            <Box sx={{
              flexGrow: 1,
              display: "flex",
              alignItems: "center",
              justifyContent: "space-evenly",
            }}/>
          </UnauthenticatedTemplate>
          <Button
            color="link"
            id="auth"
            aria-label="login"
            onClick={() => login()}
          >
            <UnauthenticatedTemplate>
              <Login fontSize="small"/>
            </UnauthenticatedTemplate>
            <AuthenticatedTemplate>
              <Logout fontSize="small"/>
            </AuthenticatedTemplate>
          </Button>

          <PopupState variant="popover" popupId="menu">
            {(popupState) => (
              <React.Fragment>
                <Button
                  id="main_menu_button"

                  aria-label="navigation"
                  size="small"
                  color="link"
                  {...bindTrigger(popupState)}>
                  <MenuIcon fontSize="small"/>
                </Button>
                <Menu id="main_menu" {...bindMenu(popupState)}>
                  <UnauthenticatedTemplate>
                    <MenuItem><Link
                      aria-label="create kogu request via wizard"
                      underline="none" color="inherit" href="/wizard">{t("Home")}</Link></MenuItem>

                  </UnauthenticatedTemplate>
                  <AuthenticatedTemplate>

                    <MenuItem><Link
                      aria-label="home"
                      underline="none" color="inherit" href="/">{t("Home")}</Link></MenuItem>
                    <Divider />
                    {HeaderRoutesArray.map((e) =>
                      <MenuItem
                        component={LinkRouter}
                        to={e.to}
                        key={(e.name)}
                        sx={{textTransform: "none",
                          fontSize: "1rem", fontWeight: 700}}
                        onClick={()=>handleRoutesChange(e.path, true)}
                        id={e.path}
                        aria-label={e.name}
                      >
                        {e.name}
                      </MenuItem>,
                    )
                    }
                    <Divider />
                    {process.env.REACT_APP_AUTHORITY_PROFILE ? <MenuItem id="profile" value="changeprofile" onClick={handleChangeProfile}>{t("defaultheader.link.editprofile")}</MenuItem> : <div></div>}
                    {process.env.REACT_APP_AUTHORITY_RESET ? <MenuItem id="password" value="handleEditassword" onClick={handleEditPassword}>{t("defaultheader.link.resetpassword")}</MenuItem> : <div></div> }


                    <MenuItem data-cy="handleExportData" id="export" value="exportData" onClick={handleExportData}>{t("defaultheader.link.exportData")}</MenuItem>
                    <MenuItem component={LinkRouter}
                      to="/signature"
                      style={{minWidth: "158px"}}
                      id="signature"
                      value="handleEditSignature"
                    >{t("defaultheader.link.signature")}</MenuItem>
                    {(props.verified === true )?
                    <MenuItem component={LinkRouter}
                      to="/substitution"
                      style={{minWidth: "158px"}}
                      id="substitution"
                      value="handleNavigateToSubstitution"
                    >{t("defaultheader.link.substitution")}</MenuItem>:
                    ""}
                    <MenuItem style={{minWidth: "158px"}}
                      id="deleteAccount"
                      value="handleDeleteMyAccount" onClick={() => {
                        if (window.confirm(t("defaultheader.link.deleteaccountconfirm"))) {
                          handleDeleteMyAccount();
                        }
                      }}>{t("defaultheader.link.deleteaccount")}</MenuItem>
                    <MenuItem style={{display: "none"}}
                      id="handleDeleteAllRequests" value="handleDeleteAllRequests" onClick={() => {
                        if (window.confirm(t("defaultheader.link.deleteallRequestsConfirm"))) {
                          handleDeleteAllRequests();
                        }
                      }}>{t("defaultheader.link.deleteallmyrequests")}</MenuItem>
                    <Divider
                      hidden={
                        (
                          props.isDemoUser === false ||
                                              props.isAuthenticated === false ||
                                              props.verified === false
                        )
                      }

                    />
                    <MenuItem
                      hidden={
                        (
                          props.isDemoUser === false ||
                          props.isAuthenticated === false ||
                          props.verified === false
                        )
                      }
                      component={LinkRouter}
                      to="/ai/"
                      style={{minWidth: "158px"}}
                      id="ai"
                      value="handleNavigateToAI"
                    >{t("defaultheader.link.ai")}</MenuItem>
                    <MenuItem
                      hidden={
                        (
                          props.isDemoUser === false ||
                          props.isAuthenticated === false ||
                          props.verified === false
                        )
                      }
                      component={LinkRouter}
                      to="/referal/"
                      style={{minWidth: "158px"}}
                      id="referal"
                      value="handleNavigateToReferal"
                    >{t("defaultheader.link.referal")}</MenuItem>
                  </AuthenticatedTemplate>
                  <UnauthenticatedTemplate>
                    <MenuItem
                      onClick={() => {
                        history.push("/wizard");
                      }}
                    >{t("defaultheader.link.wizard")
                      }</MenuItem>
                    <MenuItem onClick={() => {
                      history.push("/medicheck");
                    }}>{t("defaultheader.link.finder")}</MenuItem>
                  </UnauthenticatedTemplate>
                  <Divider />
                  <MenuItem><Link
                    aria-label="imprint"
                    cy-data="imprint_link" underline="none" color="inherit" href="/imprint">{t("imprint.title")}</Link></MenuItem>
                  <MenuItem><Link
                    aria-label="social media page"
                    underline="none" color="inherit" target="_blank" href="https://www.linkedin.com/company/patient-strength">LinkedIn</Link></MenuItem>
                  <MenuItem><Link
                    aria-label="user manual"
                    underline="none" color="inherit" target="_blank" href={t("link.to.usermanual")}>
                    {t("link.usermanual")}</Link></MenuItem>

                </Menu>
              </React.Fragment>
            )}
          </PopupState>


          <PopupState variant="popover" popupId="language">
            {(popupState) => (
              <React.Fragment>
                <Button
                  id="language-menu"
                  disableautofocusitem="true"
                  disablescrolllock="true"
                  aria-label="change language"
                  size="small"
                  color="link"

                  {...bindTrigger(popupState)}>
                  <LanguageIcon fontSize="small"/>
                </Button>
                <Menu {...bindMenu(popupState)}>
                  <MenuItem id="de" sx={{textTransform: "none", fontSize: "1rem", fontWeight: 700}} onClick={()=>handleChangeLanguage("DE")}>{t("header.language.de")}</MenuItem>
                  <MenuItem id="fr" sx={{textTransform: "none", fontSize: "1rem", fontWeight: 700}} onClick={()=>handleChangeLanguage("FR")}>{t("header.language.fr")}</MenuItem>
                  <MenuItem id="it" sx={{textTransform: "none", fontSize: "1rem", fontWeight: 700}} onClick={()=>handleChangeLanguage("IT")}>{t("header.language.it")}</MenuItem>
                </Menu>
              </React.Fragment>
            )}
          </PopupState>

        </Toolbar>
      </Container>
    </AppBar>
  );
};

const mapStateToProps = (state) => {
  return {
    itemList: state.requestList.itemList,
    searchQuery: state.requestList.searchQuery,
    userinformation: state.userinformation,
    verified: state.userinformation.user?.groups?.includes("verified"),
    isDemoUser: state.userinformation.user?.groups?.includes("demo"),
    unreadMessages: state.communication.unreadMessages,
  };
};

const mapDispatchToProps = (dispatch) => ({
  changeLang: (newLang) => dispatch(onLangChange(newLang)),
  onSearchChange: (value) => onSearchChange({value: value}),
});

export default connect(mapStateToProps, mapDispatchToProps)(DefaultHeader);
